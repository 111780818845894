/// <reference types="swiper" />
<template>
    <div class="box">
        <div class="context">
            <div class="title">新闻资讯</div>
            <div v-if="!isPhone" class="select">
                <div :class="{'select-text-activate': activateIndex === 1,'animate__rubberBand': activateIndex === 1}" class="select-text animate__animated" @click="changeIndex(1)">
                    企业新闻
                </div>
                <div :class="{'select-text-activate': activateIndex === 2,'animate__rubberBand': activateIndex === 2}" class="select-text animate__animated" @click="changeIndex(2)">
                    行业新闻
                </div>
            </div>
            <div class="swiper-dev">
                <swiper
                        :centeredSlides="true"
                        :initialSlide="initialSlide"
                        :modules="[Pagination, Navigation]"
                        :slidesPerView="slidesPerView"
                        :spaceBetween="30"
                        class="mySwiper"
                        :autoplay="true"
                        :navigation="true"
                        @activeIndexChange="activeIndexChange"
                        :key="newsList.length"
                >
                    <swiper-slide v-for="(item, index) in newsList" :key="item.id" class="animate__animated animate__bounceInDown animate__slow"
                                  :style="{'animation-delay':`${(index + 1) * 200}ms`}"
                    >
                        <div :class="{'swiper-slide-text-notActive': swiperActivateIndex !== index}"
                             class="swiper-slide-text">
                            <img :src="item.newsImg" :alt="item.keyWord" srcset=""/>
                            <div class="swiper-slide-context">
                                <div class="swiper-slide-context-title">{{item.newsTitle}}</div>
                                <div class="swiper-slide-context-time">{{item.updatedTime}}</div>
                                <div class="swiper-slide-context-text">{{item.newsContent}}</div>
                                <div class="swiper-slide-context-btn" @click="navigateTo({name: 'context',params: {id:item.id}})">MORE<i class="iconfont icon-arrow-right"></i></div>
                            </div>
                        </div>
                    </swiper-slide>
                </swiper>
            </div>
        </div>
    </div>
</template>

<script setup>
// Import Swiper Vue.js components
import {Swiper, SwiperSlide} from 'swiper/vue';
// Import Swiper styles
import 'swiper/css';

import 'swiper/css/pagination';

// import required modules
import {Pagination, Navigation} from 'swiper';

import {inject, ref} from "vue";
import {navigateTo} from "@/uitl/tool";

const isPhone = inject('isPhone')
const slidesPerView = ref('auto')
slidesPerView.value = isPhone ? 1 : 'auto'
const initialSlide = ref(1)
initialSlide.value = isPhone ? 0 : 1

const activateIndex = ref(1)
const swiperActivateIndex = ref(0)
const emit = defineEmits(['handleNewsType'])
const changeIndex = (index) => {
    activateIndex.value = index
    emit('handleNewsType',activateIndex.value)
}
const activeIndexChange = (swiper) => {
    swiperActivateIndex.value = swiper.activeIndex
}
const {newsList} = defineProps(['newsList'])
</script>

<style lang="scss" scoped src="./scss/homePage5.scss">
</style>