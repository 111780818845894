import {createRouter, createWebHistory} from 'vue-router'
import type {RouteLocationNormalized } from 'vue-router'


const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {path: '/',redirect: '/home',},
    // @ts-ignore
    { path: '/about', component: () => import('../views/AboutView.vue'), meta: { componentName: '关于我们' } },
    // @ts-ignore
    { path: '/home', component: () => import('../views/indexPage.vue'), meta: { componentName: '首页' } },
    // @ts-ignore
    { path: '/products', component: () => import('../views/ProductsView.vue'), meta: { componentName: '产品展示' } },
    // @ts-ignore
    { path: '/afterSale', component: () => import('../views/afterSaleView.vue'), meta: { componentName: '售后服务' } },
    // @ts-ignore
    { path: '/contactUs', component: () => import('../views/contactUsView.vue'), meta: { componentName: '联系我们' } },
    // @ts-ignore
    { path: '/custom', component: () => import('../views/CustomView.vue'), meta: { componentName: '定制研发' } },
    // @ts-ignore
    { path: '/recruit', component: () => import('../views/RecruitView.vue'), meta: { componentName: '企业招聘' } },
    // @ts-ignore
    { path: '/maintenance', component: () => import('../views/MaintenanceView.vue'), meta: { componentName: '智能方案' } },
    // @ts-ignore
    { path: '/news', component: () => import('../views/NewsView.vue'), meta: { componentName: '新闻资讯' } },
    // @ts-ignore
    { path: '/context/:id?', name: 'context', component: () => import('../views/Context.vue'), meta: { } },
    // 404页面路由配置
    // @ts-ignore
    {path: '/:catchAll(.*)',name: 'NotFound',component: () => import('../views/404.vue') }
  ],
  scrollBehavior(to: RouteLocationNormalized, from: RouteLocationNormalized, savedPosition ) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
})
export default router
