import axios from 'axios';
import NProgress from 'nprogress';

const instance = axios.create({
    baseURL: import.meta.env.VITE_API_URL,
    timeout: 6000,
    headers: {
        'X-Custom-Header': 'foobar',
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    }
});

let requestCounter = 0;
const getLoadingElement = () => document.getElementById('loading') as HTMLElement;

function hideLoading() {
    // 在100毫秒后检查requestCounter
    setTimeout(() => {
        requestCounter--;
        if (requestCounter === 0) {
            NProgress.done();
            getLoadingElement().style.display = 'none';
        }
    }, 100);
}

// 添加请求拦截器
instance.interceptors.request.use(function (config) {
    if (requestCounter === 0) {
        NProgress.start();
        getLoadingElement().style.display = 'flex';
    }
    requestCounter++;
    return config;
}, function (error) {
    return Promise.reject(error);
});

// 添加响应拦截器
instance.interceptors.response.use(function (response) {
    const res = response.data;
    hideLoading();
    if (res.code === 200) {
        return Promise.resolve(res);
    }
    return response;
}, function (error) {
    hideLoading();
    return Promise.reject(error);
});

export default instance;
