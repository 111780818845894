// @ts-ignore
import homePage2 from "./home/homePage2.vue"
// @ts-ignore
import footPage from "./common/footPage.vue"
// @ts-ignore
import homePage6 from "./home/homePage6.vue"
// @ts-ignore
import homePage5 from "./home/homePage5.vue"
// @ts-ignore
import homePage4 from "./home/homePage4.vue"
// @ts-ignore
import homePage from "./home/homePage.vue"
// @ts-ignore
import homePage3 from "./home/homePage3.vue"
// @ts-ignore
import headComponents from "./common/headComponents.vue"

const componentObj = {
    homePage,
    homePage2,
    homePage3,
    homePage4,
    homePage5,
    homePage6,
    footPage,
    headComponents
}
/* 全局注册组件 */
export default {
    // @ts-ignore
    install(app) :void {
        Object.keys(componentObj).forEach(key => {
            // @ts-ignore
            app.component(key, componentObj[key])
        })
    }
}