<template>
    <div class="box">
        <div class="context">
            <div class="headBox">
                <div class="headBoxLeftBg"></div>
                <div v-if="!isPhone" class="relation">
                    <div class="relationBox">
                        <span class="relationBoxTitle">关于我们</span>
                        <div class="relationBoxContext">东微智能科技有限公司</div>
                        <div class="relationBoxContext">上海茂玺商贸集团</div>
                        <div class="relationBoxContext">艺之都网络有限公司</div>
                        <div class="relationBoxContext">矩阵动力源码网站</div>
                    </div>
                    <div class="relationBox">
                        <span class="relationBoxTitle">联系我们</span>
                        <div class="relationBoxContext">客户服务</div>
                        <div class="relationBoxContext">合作洽谈</div>
                        <div class="relationBoxContext">产品采购</div>
                    </div>
                    <div class="relationBox">
                        <span class="relationBoxTitle">法律信息</span>
                        <div class="relationBoxContext">服务协议</div>
                        <div class="relationBoxContext">隐私政策</div>
                        <div class="relationBoxContext">知识产权</div>
                    </div>
                </div>
                <div v-if="!isPhone" class="code">
                    <div class="codeBox">
                        <div class="codeBg"></div>
                        <div class="codeTitle">东微智能官方公众号</div>
                    </div>
                    <div class="codeBox">
                        <div class="codeBg2"></div>
                        <div class="codeTitle">东微智能官方微信号</div>
                    </div>
                </div>
                <div v-if="isPhone" class="relationTitle" :class="{'relationTitleActivate':nowTitleIndex === 1}"
                @click="nowTitleIndex = 1"
                >关于我们</div>
                <div v-if="isPhone" class="relationTitle" :class="{'relationTitleActivate':nowTitleIndex === 2}"
                @click="nowTitleIndex = 2"
                >联系我们</div>
                <div v-if="isPhone" class="relationTitle" :class="{'relationTitleActivate':nowTitleIndex === 3}"
                @click="nowTitleIndex = 3"
                >法律信息</div>
            </div>
            <div v-if="isPhone">
                <div class="companyTitle" v-if="isPhone && nowTitleIndex === 1">
                    <div class="relationBoxContext">东微智能科技有限公司</div>
                    <div class="relationBoxContext">艺之都网络有限公司</div>
                    <div class="relationBoxContext">微行销网络有限公司</div>
                    <div class="relationBoxContext">莞企互联网络有点公司</div>
                    <div class="relationBoxContext">包拯营销有限公司</div>
                </div>
                <div class="companyTitle" v-if="isPhone && nowTitleIndex === 2">
                    <div class="relationBoxContext">客户服务</div>
                    <div class="relationBoxContext">合作洽谈</div>
                    <div class="relationBoxContext">产品采购</div>
                </div>
                <div class="companyTitle" v-if="isPhone && nowTitleIndex === 3">
                    <div class="relationBoxContext">服务协议</div>
                    <div class="relationBoxContext">隐私政策</div>
                    <div class="relationBoxContext">知识产权</div>
                </div>
            </div>
            <div class="company">dwzn</div>
            <div class="footBox">
                <div class="contactIconBox">
                    <div class="contactIcon">公司活动</div>
                    <div class="contactIcon">产品推荐</div>
                    <div class="contactIcon">企业加盟</div>
                    <div class="contactIcon">售后服务</div>
                    <div class="contactIcon">企业招聘</div>
                    <div class="contactIcon">关于我们</div>
                </div>
                <div class="contactIconBox">
                    <div class="icon-contact">
                        <i class="iconfont icon-weibo icon-size"></i>
                    </div>
                    <div class="icon-contact">
                        <i class="iconfont icon-weixin icon-size"></i>
                    </div>
                    <div class="icon-contact">
                        <i class="iconfont icon-QQ icon-size"></i>
                    </div>
                </div>
            </div>
            <div v-if="isPhone" class="code">
                <div class="codeBox">
                    <div class="codeBg"></div>
                    <div class="codeTitle">东微智能官方公众号</div>
                </div>
                <div class="codeBox">
                    <div class="codeBg2"></div>
                    <div class="codeTitle">东微智能官方微信号</div>
                </div>
            </div>
        </div>
        <div class="foot">
            <span>海南东微智能科技有限公司</span>
            <a href="https://beian.miit.gov.cn/" target="_blank">dongweizhineng.com 琼ICP备2023004069号-1版权所有</a>
        </div>
    </div>
</template>

<script setup lang="ts">
import {inject, ref} from "vue";
const isPhone = inject('isPhone')
const nowTitleIndex = ref(1)
</script>

<style lang="scss" scoped src="./scss/footPage.scss">

</style>