<template>
    <div class="box">
        <div class="context">
            <div class="title animate__animated animate__zoomIn animate__slow">
                <p>我</p>
                <p>们</p>
                <p>的</p>
                <p>优</p>
                <p>势</p>
            </div>
            <div class="orientation-div">
                <div class="orientation-div-next animate__animated animate__rollIn"
                     :style="{'animation-delay':`${(index + 1) * 200}ms`}"
                     :class="[{'orientation-div-next-activate': index === indexActivate}]" v-for="(item, index) in textList" @mouseover="changeIndex(index)">
                    <div class="orientation-div-next-bg" :style="{ backgroundImage: 'url(' + item.bgImage + ')' }"></div>
                    <span class="orientation-div-next-title">{{item.name}}</span>
                    <span>{{item.text}}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import {inject, ref} from "vue";
const isPhone = inject('isPhone')

const textList = ref([
    {name: '专业', text: '质量保证', bgImage: 'https://i.imgloc.com/2023/05/27/VUYZeV.png'},
    {name: '高性能', text: '质量保证', bgImage: 'https://i.imgloc.com/2023/05/27/VUYfkb.png'},
    {name: '稳定', text: '质量保证', bgImage: 'https://i.imgloc.com/2023/05/27/VUYCaU.png'},
    {name: '高效', text: '质量保证', bgImage: 'https://i.imgloc.com/2023/05/27/VUYHJy.png'},
    {name: '稳定', text: '质量保证', bgImage: 'https://i.imgloc.com/2023/05/27/VUYZeV.png'},
])
const indexActivate = ref(0)
const changeIndex = (index: any) => {
    indexActivate.value = index
}
</script>

<style lang="scss" scoped src="./scss/homePage3.scss">
</style>