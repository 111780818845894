<template>
    <div class="box">
        <div :class="{'flex-between-items-center': !isPhone}" class="container">
            <img v-if="!isPhone" alt="" class="headLogo" src="@/assets/img/headLogo.png" srcset="">
            <img v-else alt="" class="headLogo" src="@/assets/img/headLogoPhone.png" srcset=""
                 style="height: 13.57rem;width: 48.57rem;">
            <div v-if="!isPhone">
                <div class="tabs">
                    <div v-for="(tab, index) in tabs" :key="index" :class="{ active: index === activeTab }" class="tab"
                         @click="changeIndex(index, tab.path)">{{ tab.name }}
                    </div>
                    <img :style="{ left: activeTab * 100 / tabs.length + '%' }" class="indicator"
                         src="@/assets/img/tabsBg.png"/>
                </div>
            </div>
            <div v-if="isPhone" class="icon-gengduo-style" @click="drawer = true"><i class="iconfont icon-gengduo"></i>
            </div>
        </div>
        <el-drawer v-model="drawer"
                   :show-close="false"
                   :with-header="false"
                   class="phoneTabs"
                   direction="ttb"
                   size="100%"
                   style="background:rgba(0,0,0,.69);">
            <i class="iconfont icon-guanbi icon-guanbi-style" @click="drawer = false"></i>
            <div class="list animate__animated animate__backInLeft"
                 :style="{'animation-delay':`${(index) * 200}ms`}"
                 v-for="(item, index) in tabs" @click="changeIndex(index, item.path)">
                <div class="listBg"></div>
                <div>{{item.name}}</div>
            </div>
        </el-drawer>
    </div>
</template>

<script lang="ts" setup>
import {inject, ref} from "vue";
import router from "@/router";

const tabs = ref([
    {name:'首页', path: '/'},
    {name:'产品展示', path: '/products'},
    {name:'智能方案', path: '/maintenance'},
    {name:'售后服务', path: '/afterSale'},
    {name:'新闻资讯', path: '/news'},
    {name:'企业招聘', path: '/recruit'},
    {name:'定制研发', path: '/custom'},
    {name:'关于我们', path: '/about'},
    {name:'联系我们', path: '/contactUs'}])
const activeTab = ref(0)
const changeIndex = (index = 0, tab: any) => {
    activeTab.value = index
    router.push({ path: tab });
    drawer.value = false
}
const isPhone = inject('isPhone')
const unfoldTabs = () => {
}
const drawer = ref(false)
</script>

<style lang="scss" scoped src="./scss/headComponents.scss">
</style>