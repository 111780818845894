import router from "@/router";
export const getInnerWidth = (): boolean => {
  let isPhone: boolean = false
    if (window.innerWidth < 768) {
        console.log('当前是手机端');
        isPhone = true
    } else {
        console.log('当前是PC端');
        isPhone = false
    }
    return isPhone;
}

export const navigateTo = (option = {}) => {
    return new Promise((resolve, reject) => {
        try {
            router.push({...option});
            // @ts-ignore
            resolve();
        } catch (error) {
            reject(error);
        }
    });
};