/// <reference types="swiper" />
<template>
    <div class="box">
        <div class="context">
            <div class="title animate__animated animate__zoomIn animate__slow">
                <p>产</p>
                <p>品</p>
                <p>展</p>
                <p>示</p>
            </div>
            <div class="swiper-div">
                <swiper
                    :loop="loop"
                    :spaceBetween="30"
                    :centeredSlides="true"
                    :coverflowEffect="{
                      rotate: 50,
                      stretch: 0,
                      depth: 100,
                      modifier: 1,
                      slideShadows: true,
                    }"
                    :effect="'coverflow'"
                    :grabCursor="true"
                    :modules="[EffectCoverflow, Pagination, Navigation]"
                    :slidesPerView="slidesPerView"
                    :navigation="true"
                    class="mySwiper animate__animated animate__zoomInDown animate__slow"
                    :initialSlide="1"
                >
                    <swiper-slide v-for="item in productList" :key="item.productId">
                        <div class="swiperBg"></div>
                        <img :src="item.productImg"/>
                    </swiper-slide>
                </swiper>
            </div>
        </div>
    </div>
</template>

<script setup>
import { Swiper, SwiperSlide } from "swiper/vue";
const {productList} = defineProps(['productList'])
const isPhone = inject('isPhone')
const slidesPerView = ref(4)
slidesPerView.value = isPhone ? 'auto' : 4
const loop = ref(true)
loop.value =  !isPhone

// Import Swiper styles
import "swiper/css";

import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import 'swiper/css/navigation';

// import required modules
import { EffectCoverflow, Pagination, Navigation } from "swiper";
import {inject, ref} from "vue";
</script>

<style lang="scss" scoped src="./scss/homePage4.scss">

</style>
<style>
.swiper-button-next, .swiper-rtl .swiper-button-prev {
    width: 8.96rem;
    height: 4.81rem;
    right: 43%;
    transform: translateX(50%);
    top: 38rem;
    margin-top: 2rem;
    background: url("@/assets/img/home/swiper-button-next.png") no-repeat;
    background-size: 100% 100%;
}
.swiper-button-prev, .swiper-rtl .swiper-button-next {
    width: 8.96rem;
    height: 4.81rem;
    background: url("@/assets/img/home/swiper-button-prev.png") no-repeat;
    background-size: 100% 100%;
    left: 43%;
    transform: translateX(-50%);
    top: 38rem;
    margin-top: 2rem;
}
.swiper-button-prev:after, .swiper-rtl .swiper-button-next:after {
    content: "";
}
.swiper-button-next:after, .swiper-rtl .swiper-button-prev:after {
    content: "";
}
@media screen and (max-width: 767px) {
    .swiper-button-next, .swiper-rtl .swiper-button-prev {
        width: 38.3rem;
        height: 20.57rem;
        right: 30%;
        transform: translateX(50%);
        top: 158rem;
        margin-top: 2rem;
        background: url("@/assets/img/home/swiper-button-next.png") no-repeat;
        background-size: 100% 100%;
    }
    .swiper-button-prev, .swiper-rtl .swiper-button-next {
        width: 38.3rem;
        height: 20.57rem;
        background: url("@/assets/img/home/swiper-button-prev.png") no-repeat;
        background-size: 100% 100%;
        left: 30%;
        transform: translateX(-50%);
        top: 158rem;
        margin-top: 2rem;
    }
}
</style>