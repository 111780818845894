<template>
    <div class="box">
        <el-carousel trigger="click" height="100vh" :pause-on-hover="false">
            <el-carousel-item v-for="item in bannaData" :key="item">
                <img v-if="!isPhone" class="homeBg" :src="item.carousellUrl"/>
                <img v-else class="homeBg" :src="item.carousellMoveUrl" />
            </el-carousel-item>
        </el-carousel>
        <div class="context animate__animated animate__slideInLeft" @click="navigateTo('/maintenance')">
            <div class="title">东微智能5G万物互联</div>
            <div class="text">管理系统开始 解决方案设计开发</div>
            <div class="btn">查看更多</div>
        </div>
    </div>
</template>

<script setup>
import {inject} from "vue"
import {navigateTo} from "@/uitl/tool"
const isPhone = inject('isPhone')
const {bannaData} = defineProps(['bannaData'])
</script>

<style lang="scss" scoped src="./scss/homePage.scss">

</style>
<style>
.el-carousel__button {
    background: url("@/assets/img/home/indicator.png") no-repeat;
    background-size: 100% 100%;
    opacity: 1;
    width: 1.69rem;
    height: 1.38rem;
}
.el-carousel__indicator.is-active button {
    width: 2.31rem;
    height: 2rem;
    background: url("@/assets/img/home/indicatorActivate.png") no-repeat;
    background-size: 100% 100%;
}
.el-carousel__indicators {
    display: flex;
    align-items: center;
}
@media screen and (max-width: 767px) {
    .el-carousel__button {
        background: url("@/assets/img/home/indicator.png") no-repeat;
        background-size: 100% 100%;
        opacity: 1;
        width: 8.57rem;
        height: 7.5rem;
    }
    .el-carousel__indicator.is-active button {
        width: 12.5rem;
        height: 10.71rem;
        background: url("@/assets/img/home/indicatorActivate.png") no-repeat;
        background-size: 100% 100%;
    }
}
</style>