<template>
    <div class="box">
        <div class="context">
            <div class="title animate__animated animate__fadeInBottomLeft animate__slow">合作伙伴</div>
            <div class="parent-box animate__animated animate__fadeInBottomLeft animate__slow">
                <div class="sub-box"></div>
                <div class="sub-box"></div>
                <div class="sub-box"></div>
                <div class="sub-box"></div>
                <div class="sub-box"></div>
                <div class="sub-box"></div>
                <div v-if="!isPhone" class="sub-box"></div>
                <div v-if="!isPhone" class="sub-box"></div>
                <div class="sub-box-bg1"></div>
                <div class="sub-box-bg2"></div>
                <div class="sub-box-bg3"></div>
                <div class="sub-box-bg4"></div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import {inject} from "vue";

const isPhone = inject('isPhone')
</script>

<style lang="scss" scoped src="./scss/homePage6.scss">
</style>