<script setup>
import {RouterView, useRoute} from 'vue-router'
import {getInnerWidth} from "@/uitl/tool"
import {provide, ref, watch} from "vue";
import Loading from "@/components/Loading.vue"
const isPhone = ref(false)
isPhone.value = getInnerWidth()
provide('isPhone', isPhone.value)
watch(useRoute(),(val) => {
    if(useRoute()?.meta?.componentName !== '') {
        document.title = '东微智能|' + val.meta?.componentName + '|软件定制开发公司|企业邮箱:dongweizhineng@163.com'
    } else {
        document.title = '东微智能|App定制开发|专业(IoT平台)物联网硬件|APP软件应用订制开发服务公司'
    }
})

</script>

<template>
    <headComponents></headComponents>
    <RouterView/>
    <Loading ref="loading" id="loading" />
</template>

