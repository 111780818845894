import './assets/main.css'

import { createApp } from 'vue'
import { createPinia } from 'pinia'

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import '@/assets/iconfont/iconfont.css'
import 'animate.css';
import request from "./uitl/request"

// @ts-ignore
import App from './App.vue'
import router from './router'
import globalComponent from "@/components"

const app = createApp(App)
app.config.globalProperties.$axios = request
app.use(createPinia())
app.use(router)
app.use(ElementPlus)
app.use(globalComponent)

app.mount('#app')
