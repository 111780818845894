<template>
    <div class="box">
        <div class="context">
            <div class="title animate__animated animate__zoomIn animate__slow">
                <p>关</p>
                <p>于</p>
                <p>我</p>
                <p>们</p>
            </div>
            <div v-if="isPhone" class="sphere-img animate__animated animate__slideInRight animate__slow">
                <div class="sphere"></div>
                <img class="sphereImg" src="@/assets/img/home/sphereImg.png" alt="" srcset="" />
            </div>
            <div class="flex-direction animate__animated animate__slideInUp animate__slow">
                <div class="textTitle flex-between-items-center">东微智能</div>
                <div class="text">
                    <div class="brief-introduction">
                        <p>
                            自动化设备是自动化系统中的大型成套设备，是指机器或装置在无人干预的情况下按规定的程序或指令自动进行操作或控制的过程，自动化设备由振动盘搭配组成。自动化设备由5部分组成：程序单元、作用单元、传感单元、制定单元和控制单元。</p>
                        <p>
                            自动化是专门从事智能自动控制、数字化、网络化控制器及传感器的研发、生产、销售的高科技公司，其众多的功能模块完善的嵌入式解决方案可以最大程度地满足众多用户的个性化需求。因此，自动化是工业、农业、国防和科学技术现代化的重要条件和显著标志，自动化技术广泛用于工业、农业、军事、科学研究、交通运输、商业、医疗、服务和家庭等方面。自动化极大地提高劳动生产率，增强人类认识世界和改造世界的能力。自动化设备不仅让企业更好控制开支预算，也提高工作效率。</p>
                    </div>
                    <div class="btn" @click="navigateTo('/about')">
                        <span>了解更多</span>
                        <i class="iconfont icon-arrow-right iconfont-style"></i>
                        <i class="iconfont icon-arrow-right iconfont-style"></i>
                    </div>
                </div>
            </div>
            <div v-if="!isPhone" class="sphere-img animate__animated animate__slideInRight animate__slow">
                <div class="sphere"></div>
                <img class="sphereImg" src="@/assets/img/home/sphereImg.png" alt="" srcset="" />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import {inject} from "vue";
import {navigateTo} from "@/uitl/tool";

const isPhone = inject('isPhone')
</script>

<style lang="scss" scoped src="./scss/homePage2.scss">
.sphere {
    width: 53rem;
    height: 52rem;
    background: url("@/assets/img/home/sphere.png") no-repeat;
    background-size: 100% 100%;
    transform-origin: 52% 51%;
    margin-left: 6.89rem;
    animation: myAnimation 15s linear infinite;
}

@keyframes myAnimation {
    0% {
        transform: rotate(360deg);
    }
    100% {
        transform: rotate(0deg);
    }
}
</style>