<template>
    <div v-show="isLoading" class="loading">
        <div class="cube">
            <div class="face front"></div>
            <div class="face back"></div>
            <div class="face right"></div>
            <div class="face left"></div>
            <div class="face top"></div>
            <div class="face bottom"></div>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue';

const isLoading = ref(false);
</script>

<style scoped>
.loading {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 9999;
    perspective: 1200px;
}

.cube {
    position: relative;
    width: 120px;
    height: 120px;
    transform-style: preserve-3d;
    transform: rotateX(0deg) rotateY(0deg);
    animation: spin 3s infinite linear;
}

.face {
    position: absolute;
    width: 120px;
    height: 120px;
    border: 2px solid #fff;
    box-sizing: border-box;
    background: rgba(0, 128, 128, 0.9);
}

.front  { transform: translateZ(60px); }
.back   { transform: rotateY(180deg) translateZ(60px); }
.left   { transform: rotateY(-90deg) translateZ(60px); }
.right  { transform: rotateY(90deg) translateZ(60px); }
.top    { transform: rotateX(90deg) translateZ(60px); }
.bottom { transform: rotateX(-90deg) translateZ(60px); }

@keyframes spin {
    0% { transform: rotateX(0deg) rotateY(0deg); }
    100% { transform: rotateX(360deg) rotateY(360deg); }
}
</style>
